.news-section {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 150px;
    gap: 20px;
}

.news-section .f21 {
    max-width: 350px;
    text-align: center;
    line-height: 115%;
}