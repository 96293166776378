@media only screen and (max-width: 1250px) {
    .navbar {
        width: 900px !important;
    }

    .titlescreen {
        width: 900px !important;
    }

    .about {
        width: 900px !important;
    }

    .ac-text {
        width: 50% !important;
    }

    .foundation {
        width: 900px !important;
    }

    .groups {
        width: 900px !important;
    }

    .workers {
        width: 900px !important;
    }

    .g-selector {
        gap: 30px;
    }

    .contact {
        width: 900px !important;
    }

    .cursor {
        display: none !important;
    }

    .news-section {
        width: 900px !important;
    }
}

@media only screen and (max-width: 950px) {
    .navbar {
        width: 700px !important;
    }

    .titlescreen {
        width: 700px !important;
    }

    .about {
        width: 700px !important;
    }

    .a-content {
        flex-direction: column;
    }

    .ac-text {
        width: 100% !important;
    }

    .ac-video {
        width: 100%;
        margin-top: 50px;
    }

    .ac-video, .video-container {
        width: 100% !important;
        max-width: 100% !important;
    }

    .a-cards {
        justify-content: center !important;
        flex-wrap: wrap;
        gap: 30px;
    }

    .ac-card-container {
        width: 300px !important;
    }

    .foundation {
        width: 700px !important;
    }

    .groups {
        width: 700px !important;
    }

    .g-cards {
        justify-content: center !important;
        gap: 20px;
    }

    .group-card-container {
        width: 200px !important;
    }

    .gc-card {
        width: 100% !important;
    }

    .group-imgs-row {
        flex-wrap: wrap;
        gap: 20px;
    }

    .group-imgs-row div {
        width: calc(50% - 10px) !important;
    }

    .workers {
        width: 700px !important;
    }

    .workerrow-container {
        width: 100% !important;
    }

    .w-cards-row {
        width: 100% !important;
        justify-content: center !important;
        flex-wrap: wrap;
        gap: 30px;
    }

    .w-card {
        margin-right: 0 !important;
    }

    .g-selector {
        gap: 30px;
    }

    .contact {
        width: 700px !important;
    }

    .c-content {
        flex-direction: column;
        justify-content: flex-start !important;
        height: auto !important;
    }

    .cc-form {
        width: 100% !important;
    }

    .cc-map {
        height: 400px !important;
        width: 100% !important;
        margin-top: 50px !important;
    }

    .cursor {
        display: none !important;
    }

    .copyright {
        margin-top: 60px !important;
    }

    .news-section {
        width: 700px !important;
    }
}

@media only screen and (max-width: 680px) {
    .f48-mono {
        display: none !important;
    }

    
    .titlescreen {
        width: 350px !important;
    }

    .ts-title img {
        width: 70px !important;
    }

    .ts-title .f16 {
        margin-top: 30px !important;
    }

    .about {
        width: 350px !important;
    }

    .a-section-container {
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .ac-card-container {
        width: 100% !important;
    }

    .foundation {
        width: 350px !important;
    }

    .groups {
        width: 350px !important;
    }
    
    .g-cards {
        justify-content: flex-start !important;
        gap: 20px;
    }
    
    .group-card-container {
        width: calc(50% - 10px) !important;
    }

    .gc-card {
        width: 100% !important;
    }

    .group-imgs-row {
        flex-wrap: wrap;
        gap: 20px;
    }

    .group-imgs-row div {
        width: 100% !important;
        height: 250px !important;
    }

    .workers {
        width: 350px !important;
    }
    
    .workerrow-container {
        flex-direction: column;
        width: 100% !important;
    }

    .w-card {
        width: 100% !important;
        height: 300px !important;
    }

    .g-selector {
        gap: 30px;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start !important;
    }

    .group-navlink-container {
        width: calc(50% - 15px) !important;
    }
    
    .contact {
        width: 350px !important;
    }
    
    .ct-container {
        width: 100% !important;
        justify-content: space-between !important;
    }

    .ct-container .f36 {
        width: 100% !important;
        text-align: center;
    }
    
    .ctc-container {
        margin: 0 !important;
        gap: 15px !important;
        width: 100% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
    
    .ct-icon img {
        width: 23px !important;
        height: 23px !important;
    }

    .ccf-row {
        flex-direction: column;
        margin-bottom: 0 !important;
    }

    .ccf-input {
        width: 100% !important;
        margin-bottom: 20px;
        font-size: 16px !important;
    }

    .ccf-input::placeholder {
        font-size: 16px !important;
    }

    .cursor {
        display: none !important;
    }

    .mobile {
        display: flex !important;
    }
    
    .desktop {
        display: none !important;
    }

    .fi-col p:nth-child(2) {
        margin-bottom: 10px !important;
    }

    .fi-col p:nth-child(4) {
        margin-bottom: 0;
    }
    
    .ct-container {
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        gap: 20px;
    }
    
    .copyright {
        flex-direction: column;
        gap: 10px;
        margin-top: 50px !important;
    }
    
    .news {
        gap: 10px;
    }
    
    .contact .news {
        gap: 10px;
        width: 100%;
        align-items: center !important;
    }
    
    .news .f20 {
        font-size: 16px;
    }

    .news-section {
        width: 350px !important;
    }

    .navbar {
        display: none !important;
    }
}